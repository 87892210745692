<template>
<div id="app">
    <router-view/>
</div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
*,
::before,
::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: #333;
}
::-webkit-input-placeholder {
    color: #979797;
}
</style>