import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('../App.vue'),
    children: [
        {
            path: '',
            name: 'home',
            component: () => import('../home.vue')
        },
        {
            path: 'yzdz',
            name: 'yzdz',
            meta: { title: '一针断痔' },
            component: () => import('../yzdz-mobile.vue')
        }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
